global.latest_live_json = '[]';
global.intervals = [];
global.refresh_timer = 60000;
var start_date, end_date, last_refresh;
var select_mode = "";
var datahog_id = -1;

$(document).ready( function() {

  if($('body').data('controller') != 'chart_data' || $('body').data('action') == 'wind_rose' ) { return; }

  select_mode = $('#chart_container').data("mode"); 
  datahog_id = $('#chart_container').data("datahog-id"); 
  start_date = $('#chart_container').data("start-date");
  end_date = $('#chart_container').data("end-date");
  if(isNaN($('#chart_container').data("refresh-timer")) === false){
    refresh_timer = $('#chart_container').data("refresh-timer");
  } else {
    clearInterval(intervals);
  }
  latest_live_json = $('#chart_container').data("initial-sensor-data");
  intervals.push(setInterval(get_live_chart_data, refresh_timer));
}); 


window.get_live_chart_data = function get_live_chart_data() {
  
 $.ajax({
    url: "/chart_data_live",
    data: {
        start_date: last_refresh,
        end_date: end_date,
        mode: select_mode,
        datahog_id: datahog_id
    },
    type: "GET",
    dataType: "html",
    success: function (data) {

      // Parse and merge with what we have already
      if($.parseJSON(data).length > 0) {

        console.log("Timestamp incremented as data found : " + data.length)

        var new_json = jQuery.parseJSON(data);
        latest_live_json = latest_live_json.concat(new_json);

        // Update the timestamp
        var currtime = new Date();
        var cur_hours = (currtime.getHours() < 10 ? '0' : '') + currtime.getHours();
        var cur_mins = (currtime.getMinutes() < 10 ? '0' : '') + currtime.getMinutes();
        var cur_secs = (currtime.getSeconds() < 10 ? '0' : '') + currtime.getSeconds();

        $('div#last_update').html('<div id="last_update_val">' + cur_hours + ":" + cur_mins + ":" + cur_secs + " [" + latest_live_json.length + "]</div>");
        var currtimeUTC = new Date();
        currtimeUTC = convertDateToUTC(currtime);
        last_refresh = currtimeUTC.getFullYear() + '-' + (currtimeUTC.getMonth()+ 1) + '-' + currtimeUTC.getDate() + ' ' + currtimeUTC.getHours() + ":" + currtimeUTC.getMinutes() + ":" + currtimeUTC.getSeconds();  
      } else {

        console.log("No data found timestamp not incremented in order to accommodate datahog timestamp delays")

      }
    },
    error: function (xhr, status) {
      toastr.error("Error getting data: " + xhr.responseText);
    },
    complete: function (xhr, status) {
    }
  });

}; 


window.get_latest_chart_data_for_sensor = function get_latest_chart_data_for_sensor(dh_sensor_id) { 

  // Pulling the wrong end
  // return latest_live_json.filter( ({datahog_sensor_id}) => datahog_sensor_id === dh_sensor_id)[0].value;
  
  var sensor_arry = latest_live_json.filter( ({datahog_sensor_id}) => datahog_sensor_id === dh_sensor_id);
  return sensor_arry[sensor_arry.length -1].value;
}


window.get_sensor_average_data = function get_sensor_average_data(start_date, end_date, sensor_id) {
  var avg_val = 0;
  $.ajax({
    url: "/sensor_average_data",
    async: false,
    data: {
        start_date: start_date,
        end_date: end_date,
        datahog_sensor_id: sensor_id
    },
    type: "GET",
    dataType: "html",
    success: function (data) {
      avg_val = data;
    },
    error: function (xhr, status) {
        toastr.error("Error getting data: " + xhr.responseText);
    }
  });
  return avg_val;
}


$(document).ready(function() {

  //setup_tooltips();
  
  // register date format with moment for datatable sorting
  // $.fn.dataTable.moment( 'DD/MM/YYYY' );
  // $.fn.dataTable.moment( 'DD/MM/YYYY HH:mm' );
  
  draw_dataTable('#admin_lookup_datatable', true);

  // // Hack to force token addition to all AJAX requests - Not advised
  // $.ajaxSetup({
  //     headers: {
  //         'X-CSRF-Token': $('meta[name="_token"]').attr('content')
  //     }
  // });

});

$(document).on('click', '#select_all', function(){
  $('td input:checkbox').prop( "checked", $(this).is(':checked') );
});
  
// window.setup_tooltips = function(){
//   var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
//   var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl, { boundary: 'window'})
//   });
// }


window.draw_dataTable =  function(dTable, with_search) {
  with_search = typeof with_search !== 'undefined' ? with_search : true;
  if (with_search) {
    oTable = $(dTable).DataTable({   // returns DataTables instance rather than dataTable which is jquery object
      paging: false,
      filter: with_search,
      scrollX: true,
      autoWidth: false,
      //autoWidth: true,
      stateSave: true, 
      columnDefs: [   { targets: 'no-sort', orderable: false },
                      { targets: 'checkbox-sort', orderDataType: "dom-checkbox", type: 'numeric' },
                      { targets: 'input-char-sort', orderDataType: "dom-text" },
                      { targets: 'input-numeric-sort', orderDataType: "dom-text", type: 'numeric' }
                   ] ,
      dom: 'Bfrtip',      // https://datatables.net/reference/option/dom             
      buttons: [
              {
                  text: '<i title= "Clear search" class="fas fa-fw icon-gradient fa-remove"></i>',
                  action: function ( e, dt, node, config ) {
                    dt.search('').draw();
                  }
              }]
    });
  } else {
    oTable = $(dTable).DataTable({   // returns DataTables instance rather than dataTable which is jquery object
      paging: false,
      filter: with_search,
      autoWidth: true,
      stateSave: true, 
      columnDefs: [   { targets: 'no-sort', orderable: false },
                      { targets: 'checkbox-sort', orderDataType: "dom-checkbox", type: 'numeric' },
                      { targets: 'input-char-sort', orderDataType: "dom-text" },
                      { targets: 'input-numeric-sort', orderDataType: "dom-text", type: 'numeric' }
                   ]  
    });
  
  }
  return oTable;
}

// Ensure dataTable sorts on checked state of checkboxes & checkbox icons. or use using data { sort: ''}} on td instead
$.fn.dataTable.ext.order['dom-checkbox'] = function  ( settings, col )
  {
    return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
        return  ( $('input', td).prop('checked') ? '1' : '0' );
    } );
};
// $.fn.dataTable.ext.order['dom-text'] = function  ( settings, col )
// {
//     return this.api().column( col, {order:'index'} ).nodes().map( function ( td, i ) {
//         return $('input', td).val();
//     } );
// };

$(document).on('click', '#select_all', function(){
  $('td input:checkbox').prop( "checked", $(this).is(':checked') );
});

$(document).on('click', '.item_delete', function(){
  fnDeleteItemConfirmation($(this).data('item-type'), $(this).data('item-id'), $(this).data('dialog-title'), $(this).data('dialog-prompt') );
  return false;
});


window.fnDeleteItemConfirmation = function(item_type, item_id, title, prompt) {
  
  if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html(title) }
  if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html(prompt) }
  $('#delete-dialog').modal('show');
  
  // Disable previous binds & re-associate
  $('#button-yes').off("click");
  $('#button-yes').click(function () {
    fnDelItemConfirmed(item_type, item_id);
    $('#delete-dialog').modal('hide');
    if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html("Delete Confirmation") }
    if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html("Are you sure you want to delete this item?") }
  }); 
  $('.button-no').click(function() {
    $('#delete-dialog').modal('hide');
    if ( title  !=  undefined ) {  $('#delete-dialog .modal-title').html("Delete Confirmation") }
    if ( prompt != undefined ) {  $('#delete-dialog .modal-body .container-fluid p').html("Are you sure you want to delete this item?") }
  });
  
  return false;
}

window.fnDelItemConfirmed = function(item_type, item_id) {
  var jqxhr = $.ajax({url:'/' + item_type + 's/' + item_id, type: 'delete', dataType: 'script'});
  jqxhr.done(function(data) { 
    toastr.success("Item deleted successfully");
    $("tr[data-id='" + item_id + "'").remove();
  });

  jqxhr.fail(function(xhr, ajaxOptions, thrownError) { 

    // xhr is a XMLHttpRequest object.
    toastr.error("An exception was thrown deleting the row : " + xhr.responseText);    
  });
  return false;
}


window.convertDateToUTC = function convertDateToUTC(date) { return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); }


window.setup_upload_datasheet_dialog = function setup_upload_datasheet_dialog(target_upload_url) {

  strSuccessSummary = '';
  strFailureSummary = '';  

  Dropzone.autoDiscover = false;

  // headers: {
  //         'X-CSRF-Token': $('#dropzone-upload-container meta[name="token"]').attr('content'),
  //         'csrf-token': $('#dropzone-upload-containermeta[name="token"]').attr('content'),
  //         'X-CSRF-TOKEN': $('#dropzone-upload-container meta[name="token"]').attr('content')
  //       },
  //($('body').data('controller') != 'datahogs', 1, 10),
  var myDropzone = new Dropzone('div#upload-dropzone', {       
      url: target_upload_url,
      paramName: "file", 
      maxFilesize: 10.0, 
      maxFiles: 1,
      parallelUploads: 10,
      uploadMultiple: false,
      autoProcessQueue: false,
      headers: {
        'X-CSRF-Token': $('meta[name=csrf-token]').attr('content')
      },
      init: function() {
              // if ( $('#document_category').length ) {
              //   this.on("sending", function(file, xhr, formData){
              //           formData.append("document_category", $("#document_category").val() );
              //   });
              // }
      },
      success: function success(file, message) {
        
        strSuccessSummary += message + "<br />" + file.name + "<br />"; 

        if (file.previewElement) {
          return file.previewElement.classList.add("dz-success");
        }
        
      },
      error: function error(file, message) {

        strFailureSummary += file.name + "<br />" + message + "<br />";

        if (file.previewElement) {
          file.previewElement.classList.add("dz-error");
          if (typeof message !== "String" && message.error) {
            message = message.error;
          }
          for (var _iterator7 = file.previewElement.querySelectorAll("[data-dz-errormessage]"), _isArray7 = true, _i7 = 0, _iterator7 = _isArray7 ? _iterator7 : _iterator7[Symbol.iterator]();;) {
            var _ref6;

            if (_isArray7) {
              if (_i7 >= _iterator7.length) break;
              _ref6 = _iterator7[_i7++];
            } else {
              _i7 = _iterator7.next();
              if (_i7.done) break;
              _ref6 = _i7.value;
            }

            var node = _ref6;

            node.textContent = message;
          }
        }

      },
      queuecomplete: function(file) {
        if(strSuccessSummary != '') {
          toastr.success("The following files uploaded successfully:- <br /><br />" + strSuccessSummary);
        }
        if(strFailureSummary != '') {
          toastr.error("The following files failed to import:- <br /><br />" + strFailureSummary);
        }
      },

  });

  myDropzone.on("sending", function(file, xhr, formData) {

    // Issues with CSRF tokens with file submissions 
    // formData.append("authenticity_token", $('input[name="authenticity_token"]').val());
    // formData.append("X-CSRF-Token", $('meta[name=csrf-token]').attr('content'));

    // We could be just uploading the sensors rather than trying to create the hog. Add the id to the form if its in the table data
    if(typeof $('#datahog_sensor_datatable').data("datahog-id") !== 'undefined') {
      formData.append("datahog_id", $('#datahog_sensor_datatable').data("datahog-id"));
    }    
  });

  $('#bulk_upload_button').on('click', function () {
      myDropzone.processQueue();
      return false;
  });
  

}

var dtTableData;
var start_date, end_date, last_refresh;
var select_mode = "";
var datahog_id = -1;
var refresh_timer = 10000;
var intervals = [];

$(document).ready( function() {

  if($('body').data('controller') != 'table_data') { return; }


    var refresh_time = new Date();
    last_refresh = refresh_time.getFullYear() + '-' + (refresh_time.getMonth()+ 1) + '-' + refresh_time.getDate() + ' ' + refresh_time.getHours() + ":" + refresh_time.getMinutes() + ":" + refresh_time.getSeconds();  

    select_mode = $('#table_data_datatable').data("mode"); 
    datahog_id = $('#table_data_datatable').data("datahog-id"); 
    start_date = $('#table_data_datatable').data("start-date");
    end_date = $('#table_data_datatable').data("end-date");
    refresh_timer = $('#table_data_datatable').data("refresh-timer");
    
    setup_table_data_live();
        
    // Make random additions, updates, 
    // and deletions
    intervals.push(setInterval(get_live_table_data, refresh_timer));
    

    // function stopIt () { 
    //     intervals.forEach(function(i) {
    //         clearInterval(i); 
    //     });
    // }
    // setTimeout(stopIt, 40000);

    $("#download_data_form").submit(function(event) {
      
      // Something is happening and stop them from clicking again
      toastr.info("Your download request has been initiated. It can take some time to complete. "); 
      //$('#download-block').append('Live updates are paused. Click <a href="' + window.location.href + '">here</a> to restart');
      $('#download_data_form').hide();
      $('#notify_of_activity').show();

      // Stop all requests and display a restart option
      intervals.forEach(function(i) {
        intervals.forEach(function(i) {
            clearInterval(i); 
        });
      });

      event.preventDefault();

      // Lets ensure we let all the existing requests finish
      setTimeout(initiate_download, refresh_timer + 500);
    });
});  

function get_live_table_data() {

  $.ajax({
    url: "/table_data_live",
    data: {
        start_date: last_refresh,
        end_date: end_date,
        mode: select_mode,
        datahog_id: datahog_id
    },
    type: "GET",
    dataType: "html",
    success: function (data) {

      // Must not be empty
      if(!!data) {

        console.log("Timestamp incremented as data found : " + data.length)

        // Remove the class for older rows
        $('tr.highlight').removeClass('highlight');

        // Destroy the table, add to the dom and reinstate
        if(data.length > 0) {
          $('#table_data_datatable tbody td.newest').html('');
        }
        dtTableData.destroy();
        $('#table_data_datatable tbody').prepend(data);
        setup_table_data_live();

        // Update the timestamp
        var currtime = new Date();
        currtime = convertDateToUTC(currtime);
        last_refresh = currtime.getFullYear() + '-' + (currtime.getMonth()+ 1) + '-' + currtime.getDate() + ' ' + currtime.getHours() + ":" + currtime.getMinutes() + ":" + currtime.getSeconds();  
      }
      
    },
    error: function (xhr, status) {
        toastr.error("Error getting data: " + xhr.responseText);
    },
    complete: function (xhr, status) {
        //$('#showresults').slideDown('slow')
    }
  });


}


function initiate_download() {
  toastr.info("Your live updates will be paused during the download process. Please wait. ");

  // We're going to need to do this with ajax because of the timeout delay
  // headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}, responseType: "arraybuffer"}).             
  //contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  //contentType: "text/html; charset=utf-8",
  // headers: { 'Accept': 'application/octet-stream; charset=utf-8' },
  // headers: {'Accept': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8"},
  // headers: {'Accept': "application/octet-stream; charset=UTF-8"},
  //responseType: "arraybuffer",
  // responseType: 'blob',
  // 
  
  $.ajax({
    url:'/generate_export_file',
    timeout: 0,
    type:'GET',
    data:$("#download_data_form").serialize(),
    success:function(data, textStatus, jqXHR ){     
      $('#download_div').html(data);
      $('#download_div').show();      
    },
    error: function (xhr, status) {
      toastr.error("Error getting data: " + xhr.responseText);
      $('#download_data_form').show();
    },
    complete: function (xhr, status) {
      intervals.push(setInterval(get_live_table_data, refresh_timer));
      $('#notify_of_activity').hide();

      toastr.success("Your spreadsheet was successfuly generated. Please click the link to download. Please note that this document has been added to your document archive. ");

      // Once our download is complete and requested reset
      $('.download_pre_gen_file').click(function() {
        $('#download_data_form').show();
        $('#download_div').html('');
      });
    }
  });

}

function setup_table_data_live() {
  dtTableData = $('#table_data_datatable').DataTable({
    paging: false,
    ordering: false,
    scrollX: true,
    autoWidth: false,
    //autoWidth: true,
    // orderFixed: [4, 'desc'],
    searching: false,
    lengthChange: false,
    //displayStart: 5,
  });
  dtTableData.order([7, 'desc']).draw();
}

$(document).ready( function() {
  if($('body').data('controller') != 'datahogs') { return; }

  //draw_dataTable('#datahog_datatable', false);
  oTable = $('#datahog_datatable').DataTable({   // returns DataTables instance rather than dataTable which is jquery object
      paging: false,
      filter: false,
      scrollX: true,
      autoWidth: false,
      //autoWidth: true,

      stateSave: true, 
      columnDefs: [   { targets: 'no-sort', orderable: false },
                      { targets: 'checkbox-sort', orderDataType: "dom-checkbox", type: 'numeric' },
                      { targets: 'input-char-sort', orderDataType: "dom-text" },
                      { targets: 'input-numeric-sort', orderDataType: "dom-text", type: 'numeric' }
                   ] ,
      // dom: 'Bfrtip',      // https://datatables.net/reference/option/dom             
      buttons: [
              {
                  text: '<i title= "Clear search" class="fas fa-fw icon-gradient fa-remove"></i>',
                  action: function ( e, dt, node, config ) {
                    dt.search('').draw();
                  }
              }]
    });

});  
